<template>
    <cluji-card>
        <template #title>
            <b-card-title>Wiki
                <b-button v-if="isBureau()" pill variant="success" size="sm" class="ml-2"
                          v-b-tooltip.hover="'Nouveau dossier'" @click="newFolder">
                    <font-awesome-icon icon="folder-plus"/>
                </b-button>
            </b-card-title>
            <b-card-sub-title>Informations sur le Cluji et le site</b-card-sub-title>
        </template>

        <dossier-wiki v-for="dossier in tree" :dossier="dossier" :key="dossier.id" @reload-tree="loadData"/>
    </cluji-card>
</template>

<script>
    import {apiPath}  from '@/util/http';
    import alert      from '@/util/alert';
    import {isBureau} from '@/util/auth';

    const EditFolderModal = () => import('@/components/modals/wiki/EditFolderModal');
    const DossierWiki     = () => import('@/components/wiki/DossierWiki');
    const ClujiCard       = () => import('@/components/ClujiCard');

    export default {
        name: "WikiHome",
        components: {DossierWiki, ClujiCard},
        data: () => ({
            tree: []
        }),
        methods: {
            isBureau,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('wiki_dossier_list_visible'))
                    .then(response => this.tree = response.data)
                    .catch(() => this.$toaster.error("Impossible de charger l'arborescence des articles"))
                    .finally(alert.stopLoading);
            },
            newFolder() {
                this.$store.dispatch('modal/create', {
                    component: EditFolderModal,
                    props: {callback: () => this.loadData()}
                });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>